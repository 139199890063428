
import {
    defineComponent, ref, Ref, watch
} from 'vue';
import {
    listPagination,
    ListHeaderItem,
    RequestData, getSearch, ListActionItem
} from '@/components/common/list';
import apartmentEditDialog from '@/components/view/pm/apartment-edit-dialog';
import addButton from '@/components/common/add-button/index.vue';
import departmentSetDialog from '@/components/view/office/department-set-dialog';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import chooseBuildRoomInit from '@/methods/building-apt-choose';
import { ChangeWordList } from '@/methods/setText';
import { community } from '@/data';
import { updateSortField, getSortImg, getInitSortField } from '@/methods/sort-table';
import {
    getProjectData, isShowDepartmentSetDialog, updateToList,
    isShowApartmentEditDialog, communityFormData, officeFormData,
    operate
} from './util';

export default defineComponent({
    components: {
        listPagination,
        apartmentEditDialog,
        propertyBreadCrumb,
        addButton,
        departmentSetDialog
    },
    setup() {
        const breadCrumb = ref([{ label: ChangeWordList.ApartmentOrDepartment }]);
        // 办公、社区标志位
        const projectType = community.ProjectType;
        const headers: Ref<Array<ListHeaderItem>> = ref([]);
        const listRequestData: Ref<RequestData> = ref({
            url: '',
            param: getSearch({})
        });
        const action: Ref< ListActionItem[] > = ref([]);
        watch(projectType, () => {
            const projectData = getProjectData()[projectType.value];
            headers.value = projectData.header;
            listRequestData.value = projectData.requestData;
            action.value = projectData.action;
            breadCrumb.value = [{ label: ChangeWordList.ApartmentOrDepartment }];
            // 获取初始排序
            const initSortRes = getInitSortField(projectType.value === 'community' ? 'pmComApartment' : 'pmOfficeDepartment');
            listRequestData.value.param.Sort = initSortRes.sort;
            listRequestData.value.param.SortField = initSortRes.sortField;
        }, {
            immediate: true
        });
        const { buildOptions, aptOptions, setRoomOption } = chooseBuildRoomInit();

        const saveParamInPath = ref(false);
        const searchList = () => {
            updateToList.value += 1;
            saveParamInPath.value = true;
        };
        watch(() => listRequestData.value.param.Build, (value) => {
            listRequestData.value.param.Apt = 'all';
            if (value === 'all') setRoomOption();
            else setRoomOption(value as string);
        });

        const addDepartment = () => {
            operate.value = 'add';
            officeFormData.value.DepartmentName = '';
            officeFormData.value.ID = '';
            officeFormData.value.Floor = [];
            isShowDepartmentSetDialog.value = true;
        };

        // 获取排序方式, 更新表格数据
        function getSortRes(table: string, sortField: string) {
            const {
                sort
            } = updateSortField(table, sortField);
            listRequestData.value.param.Sort = sort;
            listRequestData.value.param.SortField = sortField;
            updateToList.value += 1;
        }

        return {
            headers,
            listRequestData,
            updateToList,
            isShowApartmentEditDialog,
            breadCrumb,
            buildOptions,
            aptOptions,
            searchList,
            saveParamInPath,
            projectType,
            ChangeWordList,
            addDepartment,
            isShowDepartmentSetDialog,
            action,
            communityFormData,
            officeFormData,
            operate,
            getSortRes,
            getSortImg
        };
    }
});
