import { ref, Ref } from 'vue';
import {
    ListHeaderItem, RequestData, getSearch, ListActionItem
} from '@/components/common/list';
import remove from '@/methods/remove-func';
import { ApartmentForm } from '@/components/view/pm/apartment-edit-dialog';
import { DepartmentForm } from '@/components/view/office/department-set-dialog';
import router from '@/router';
import peopleDeviceApi from '../../../api/community/people-device';

type ProjectDataType = {
    [key in ProjectType]: {
        header: Array<ListHeaderItem>;
        requestData: RequestData;
        action: ListActionItem[];
    }
}
const updateToList = ref(0);
const isShowDepartmentSetDialog = ref(false);
const isShowApartmentEditDialog = ref(false);
const operate: Ref< OperateType > = ref('add');

const communityFormData: Ref<ApartmentForm> = ref({
    ID: '',
    AptName: '',
    CallType: 0,
    EnableIpDirect: 0,
    TempKeyPermission: 0,
    LimitCreateQRcode: 0,
    LimitFamilyMember: 0,
    FamilyMemberControl: 0,
    AllowCreateSlaveCnt: '',
    RegisterFaceControl: 1,
    LimitRegisterFace: 0,
    Area: null,
    CommunalFee: 1,
    AutoSendBills: 1,
    IsDefaultCommunalFee: 1
});
const officeFormData: Ref<DepartmentForm> = ref({
    DepartmentName: '',
    ID: '',
    Floor: []
});
const getProjectData = () => {
    const communityHeader: Array< ListHeaderItem > = [
        {
            name: 'Build',
            label: WordList.DeviceDetailDetailUnitName
        }, {
            name: 'AptNumber',
            label: WordList.DeviceDetailDetailRoomName,
            headerType: 'customize'
        }, {
            name: 'AptName',
            label: WordList.RDeviceSearchLabelRoomNumber,
            headerType: 'customize'
        }, {
            name: 'Residents',
            label: WordList.RDeviceResidents
        }, {
            name: 'Devices',
            label: WordList.OrderDevice
        }
    ];
    const officeHeader: Array< ListHeaderItem > = [
        {
            name: 'DepartmentName',
            label: WordList.TabelPersonUserInHtmlName,
            headerType: 'customize'
        }, {
            name: 'Personnels',
            label: WordList.ProperAllTextPeople
        }, {
            name: 'Devices',
            label: WordList.OrderDevice
        }
    ];
    const communityRequestData: RequestData = {
        url: 'v3/web/community/user/getRoomListByNewPm',
        param: getSearch({
            Build: 'all',
            Apt: 'all',
            Key: '',
            Sort: '',
            SortField: ''
        })
    };
    const officeRequestData: RequestData = {
        url: 'v3/web/office/department/getDepartmentList',
        param: getSearch({
            DepartmentName: '',
            Sort: '',
            SortField: ''
        })
    };
    // 社区：显示房间信息，可进入详情、编辑操作
    const communityAction: ListActionItem[] = [{
        type: 'info2',
        event(data: ApartmentForm) {
            router.push(`/propertyIndex/apartmentInfo?ID=${data.ID}`);
        },
        class: 'el-icon-my-info info-icon',
        title: WordList.PersonuserInfo
    }, {
        type: 'edit2',
        event(data: ApartmentForm) {
            communityFormData.value = data;
            isShowApartmentEditDialog.value = true;
        },
        class: 'el-icon-my-modify modify-icon',
        title: WordList.TabelConfigInHtmlModify
    }];
    // 办公：显示部门列表，可进行编辑、删除操作
    const officeAction: ListActionItem[] = [{
        type: 'edit2',
        event(data: DepartmentForm) {
            operate.value = 'edit';
            officeFormData.value.ID = data.ID;
            officeFormData.value.DepartmentName = data.DepartmentName;
            if (data.Floor) {
                officeFormData.value.Floor = data.Floor === 'all' ? ['all'] : (data.Floor as string).split(';');
            } else {
                officeFormData.value.Floor = [];
            }
            isShowDepartmentSetDialog.value = true;
        },
        class: 'el-icon-my-modify modify-icon',
        title: WordList.TabelConfigInHtmlModify
    }, {
        type: 'delete2',
        event(data: { ID: string }) {
            remove(WordList.ProperAllTextDeleteTip, () => {
                peopleDeviceApi.delDepartment({ ID: data.ID }, () => {
                    updateToList.value += 1;
                });
            });
        },
        class: 'el-icon-delete delete-icon',
        title: WordList.TabelConfigInHtmlDelete
    }];
    const projectData: ProjectDataType = {
        community: {
            header: communityHeader,
            requestData: communityRequestData,
            action: communityAction
        },
        office: {
            header: officeHeader,
            requestData: officeRequestData,
            action: officeAction
        }
    };
    return projectData;
};

export default null;
export {
    getProjectData,
    isShowDepartmentSetDialog,
    isShowApartmentEditDialog,
    updateToList,
    communityFormData,
    officeFormData,
    operate
};